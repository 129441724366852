body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "me_quran";
  src: local("me_quran"), url(./assets/fonts/me_quran.woff) format("woff");
}

@font-face {
  font-family: "scheherazade";
  src: local("scheherazade"),
    url(./assets/fonts/scheherazade-webfont.woff) format("woff");
}

@font-face {
  font-family: "kfc";
  src: local("kfc"), url(./assets/fonts/kfc_naskh-webfont.woff) format("woff");
}

@font-face {
  font-family: "amiri_quran";
  src: local("amiri_quran"), url(./assets/fonts/amiri_quran.woff) format("woff");
}

@font-face {
  font-family: "global_quran";
  src: local("global_quran"),
    url(./assets/fonts/global_quran.ttf) format("truetype");
}

@font-face {
  font-family: "al_quran_al_kareem";
  src: local("al_quran_al_kareem"),
    url(./assets/fonts/al_quran_al_kareem.ttf) format("truetype");
}

html,
body,
#app,
#app > div {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
